<template>
  <div class="min-h-dvh bg-gray-50 lg:grid lg:h-full lg:grid-cols-12">
    <AppMenu class="hidden lg:col-span-3 lg:block xl:col-span-2" />
    <div class="flex h-screen flex-col overflow-auto lg:col-span-9 xl:col-span-10">
      <AppHeader />
      <div class="relative grow">
        <slot />
      </div>
      <AppSearchbar />
    </div>
  </div>
</template>
