<template>
  <nav class="flex justify-between bg-white p-5 lg:hidden">
    <NuxtLink to="/">
      <UiLogo />
    </NuxtLink>
    <AppHamburger
      v-body-scroll-lock="overlayMenuOpen"
      @toggle-menu="toggleMenu"
    >
      <TransitionGroup
        name="slide"
        tag="div"
      >
        <div
          v-if="overlayMenuOpen"
          class="bg-primary absolute inset-y-0 right-0 z-50 size-full duration-1000"
        >
          <AppMenu @toggle-menu.stop="toggleMenu" />
        </div>
      </TransitionGroup>
    </AppHamburger>
  </nav>
  <header class="text-primary flex items-center justify-between bg-white p-5 lg:px-8 lg:pt-6">
    <h2>
      {{ $route.meta.title }}
    </h2>
    <Profile />
  </header>
  <div class="border-y border-gray-200 bg-white px-5 py-3 text-xs text-gray-500 lg:pl-8">
    <UiBreadcrumbs :crumbs="[{label: $route.meta.title, to: '/'}]" />
  </div>
</template>

<script setup lang="ts">
import {vBodyScrollLock} from '@directives/body-lock';

const overlayMenuOpen = ref(false);

function toggleMenu() {
  overlayMenuOpen.value = !overlayMenuOpen.value;
}
</script>

<style scoped>
.slide-enter-active {
  animation: slide-in 0.5s ease-out;
}

.slide-leave-active {
  animation: slide-out 0.5s ease-in forwards;
}

@keyframes slide-in {
  from {
    transform: translateX(100vw);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0)
  }

  to {
    transform: translateX(100vw)
  }
}
</style>

