import type {Ref} from 'vue';

export const vBodyScrollLock = (el: HTMLBodyElement, binding:Ref): void  => {
  const html = document.querySelector('html');
  const body = document.querySelector('body');

  if (binding.value) {
    if (html && body) {
      html.style.margin = '0';
      html.style.height = '100%';
      html.style.overflow = 'hidden';
      body.style.margin = '0';
      body.style.height = '100%';
      body.style.overflow = 'hidden';
    }
  } else {
    if (html && body) {
      html.style.margin = '';
      html.style.height = '100%';
      html.style.overflow = '';
      body.style.margin = '';
      body.style.height = '100%';
      body.style.overflow = '';
    }
  }
};
