<template>
  <div class="sticky bottom-0 z-0 flex w-full items-center justify-between gap-4 border-t border-gray-500 bg-white px-5 py-4 lg:hidden">
    <FormKit
      type="search"
      placeholder="Zoeken"
      outer-class="$reset mb-0 h-full"
      :floating-label="false"
      suffix-icon="search"
    />
    <UiButton
      type="primary"
      icon="fa-solid:filter"
      label="Filteren"
      suffix-icon="search"
      class="self-start"
      @click="toggleFilters"
    />
  </div>
  <TransitionGroup
    name="filter"
    tag="div"
  >
    <UiFilter
      v-if="filersOpen"
      v-model="filersOpen"
    >
      <FiltersGeneral />
    </UiFilter>
  </TransitionGroup>
</template>

<script lang="ts" setup>
const filersOpen = ref(false);

const toggleFilters = () => {
  filersOpen.value = !filersOpen.value;
};
</script>

<style scoped>
.filter-enter-active {
  animation: pop-in 0.5s ease-out;
}

.filter-leave-active {
  animation: pop-out 0.5s ease-in forwards;
}

@keyframes pop-in {
  from {
    transform: translateY(100vw);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes pop-out {
  from {
    transform: translateY(0)
  }

  to {
    transform: translateY(200vw)
  }
}
</style>
