<template>
  <div class="absolute top-20 z-50 size-full space-y-10 rounded-t-3xl bg-white px-5 pb-8 pt-14 shadow-2xl">
    <header class="flex items-center justify-between">
      <UiButton
        label="wissen"
        class="text-primary-200 text-xs"
      />
      <h2>Filteren</h2>
      <UiButton
        class="gap-x-1.5 text-gray-300"
        label="Sluiten"
        icon="fa6-solid:xmark"
        @click="closeFiler"
      />
    </header>
    <slot />
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  modalValue: boolean
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', modalValue: boolean): void
}>();

const closeFiler = () => {
  emit('update:modelValue', false);
};
</script>
