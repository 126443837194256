<template>
  <div class="relative">
    <div
      class="hidden max-w-44 cursor-pointer items-center gap-x-2 overflow-hidden bg-white p-3 hover:bg-gray-50 lg:flex"
      @click="showSubmenu = !showSubmenu"
    >
      <UiIcon
        class="text-gray-500"
        name="fa6-solid:user"
        size="extra-small"
      />
      <p class="text-primary overflow-hidden text-ellipsis text-sm">
        {{ user.name }}
      </p>
      <UiIcon
        class="text-gray-500 duration-100"
        :class="{'rotate-180': showSubmenu}"
        name="fa6-solid:chevron-down"
        size="extra-small"
      />
    </div>
    <ul
      v-if="showSubmenu"
      class="absolute z-50 w-full border border-gray-50 bg-white text-sm"
    >
      <li
        class="hover:bg-melon cursor-pointer p-3"
        @click="showProfile"
      >
        Mijn profiel
      </li>
      <li
        class="hover:bg-melon cursor-pointer p-3"
        @click="signOut"
      >
        Uitloggen
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import {useRouter} from 'vue-router';

const showSubmenu = ref(false);
const router = useRouter();
const {signOut} = useSession();
const user = useSofieUser();

const showProfile = () => {
  router.push({
    name: 'profile',
  });
};
</script>
