<template>
  <button
    class="bg-primary flex w-14 appearance-none items-center justify-center text-white"
    @click="$emit('toggleMenu')"
  >
    <UiIcon name="solar:hamburger-menu-linear" />
    <slot />
  </button>
</template>

