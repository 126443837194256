<template>
  <div class="h-full">
    <FormKit
      type="form"
      submit-label="Filters toepassen"
    >
      <FormKitSchema :schema="schema" />
    </FormKit>
  </div>
</template>

<script lang="ts" setup>
const schema = [
  {

  },
];
</script>
