<template>
  <div class="h-screen space-y-28">
    <aside class="bg-primary flex h-full flex-col gap-8 max-lg:justify-between max-lg:gap-4 lg:sticky lg:z-50 lg:w-full lg:space-y-0">
      <header class="flex items-center justify-between p-5 pb-0 pr-10 lg:justify-center lg:px-12 lg:pt-11">
        <UiLogo />
        <button
          class="text-white lg:hidden"
          @click="$emit('toggleMenu')"
        >
          <UiIcon name="ph:x" />
        </button>
      </header>
      <div class="px-14 lg:px-0">
        <div class="flex flex-col text-white lg:gap-0">
          <NuxtLink
            v-for="(menuItem, index) in menu"
            :key="index"
            class="hover:bg-primary-600 flex items-center justify-between border-transparent px-10 py-5 text-white lg:border-l-4"
            :to="menuItem.url"
          >
            {{ menuItem.label }}
            <UiIcon
              name="material-symbols:chevron-right"
              size="small"
            />
          </NuxtLink>
        </div>
      </div>
      <div class="bottom-0 w-full px-4 pb-12 lg:absolute">
        <UiButton
          class="mx-auto flex items-center gap-x-1 text-white"
          icon="fa6-solid:arrow-right-from-bracket"
          label="Uitloggen"
          size="small"
          @click="signOut"
        />
      </div>
    </aside>
  </div>
</template>

<script setup lang="ts">
defineEmits<{
  (e: 'toggleMenu', visibility: boolean): void
}>();

const user = useSofieUser();

const isAdmin = computed(() => {
  return user.value.is_admin;
});

const menu = [
  {label: 'Orders', url: '/'},
  {label: 'Calculator', url: '/calculator'},
  {label: 'Klanten', url: '/klanten'},
  {label: 'Beheer bekabeld', url: '/beheer/bekabeld'},
  isAdmin ? {label: 'Medewerkers', url: '/medewerkers'} : {},
  {label: 'Status', url: '/status'},
];

const {signOut} = useSession();
</script>

<style scoped>
.router-link-active {
  @apply lg:font-medium lg:bg-primary-600 lg:border-white;
}
</style>
